import { useRouter, useState } from "#imports";

export function useDetails() {
  const router = useRouter();
  const refDetails = useState<
    | {
        title: string;
      }
    | undefined
  >("details");

  router.beforeEach((to, from) => {
    if (
      to.params.area !== from.params.area ||
      to.params.detail !== from.params.detail ||
      to.params.type !== from.params.type
    ) {
      refDetails.value = undefined;
    }
  });

  return refDetails;
}
